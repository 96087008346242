import { Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery } from "@mui/material"
import React from "react"
import { ICampaignSummary } from "../../../common/interfaces/OrganisationSummary"
import moment from "moment"

interface InputProps {
	topCampaigns: ICampaignSummary[]
	currency: string
}

const TopEventsSection = (props: InputProps) => {

	const isMobile = useMediaQuery("(max-width:600px)")

	let currencyFormatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: props.currency ?? "GBP",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	})
	return (
		<section className="top-events-section">
			<h2>Top 3 Campaigns</h2>
			{props.topCampaigns && props.topCampaigns.length > 0 ? (
				<Card>
					<CardContent>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>
										<div className="table-header-cell">
											<span>Campaign Name</span>
										</div>
									</TableCell>
									<TableCell className="hide-on-mobile">
										<div className="table-header-cell">
											<span>Campaign Date</span>
										</div>
									</TableCell>
									<TableCell  className="hide-on-mobile">
										<div className="table-header-cell center">
											<span>Bid / Purchase / Pledge Count</span>
										</div>
									</TableCell>
									<TableCell>
										<div className="table-header-cell center">
											<span>Gross Funds Collected</span>
										</div>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{props.topCampaigns.sort((a, b) => b.grossFundsCollected - a.grossFundsCollected).map((campaign: ICampaignSummary) => (
									<TableRow key={campaign.campaignId}>
										<TableCell>{campaign.campaignName}</TableCell>
										<TableCell className="hide-on-mobile">{moment(campaign.campaignDate).format("DD/MM/YYYY")}</TableCell>
										<TableCell className="hide-on-mobile">
										<div className="center">
										{campaign.totalBidCount}
										</div>
											
											</TableCell>
										<TableCell>
										<div className="center">
											{currencyFormatter.format(campaign.grossFundsCollected)}
										</div>
											</TableCell>
									</TableRow>

								))}
							</TableBody>
						</Table>
					</CardContent>
				</Card>
			) : (
				<Card>
					<CardContent>
						<div className="no-entries">
							No data found
						</div>
					</CardContent>
				</Card>
			)}

		</section>
	)
}

export default TopEventsSection